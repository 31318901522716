<template>
  <BRow class="flex-col-reverse md:flex-row m-0">
    <BCol
      md="8"
      class="flex flex-column px-[24px] mt-[16px]"
    >
      <BRow class="gap-[16px] m-0">
        <b-col
          md="12"
          class="p-0 flex items-center"
        >
          <b-col
            md="4"
            class="p-0"
          >
            Email
          </b-col>
          <b-col
            md="7"
            class="border rounded-[8px] p-[7px] bg-[#E2E2E2]"
          >
            {{ dataDetail.email.new || dataDetail.email.old || '-' }}
          </b-col>
        </b-col>

        <b-col
          md="12"
          class="p-0 flex items-center"
        >
          <b-col
            md="4"
            class="p-0"
          >
            Nama Lengkap
          </b-col>
          <b-col
            md="7"
            class="border rounded-[8px] p-[7px]"
          >
            {{ dataDetail.full_name.new || dataDetail.full_name.old || '-' }}
          </b-col>
          <b-col
            v-if="isNewData(dataDetail.full_name.new, dataDetail.full_name.old)"
            cols="1"
            class="p-0 flex"
          >
            <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
              <span
                id="full-name"
                class=" k-info-circle h-fit rounded-full text-white text-2xl"
              />
              <BPopover
                target="full-name"
                triggers="hover"
                placement="top"
              ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.full_name.old || 'Tidak ada data sebelumnya' }}</span></BPopover>
            </div>
          </b-col>
        </b-col>

        <b-col
          md="12"
          class="p-0 flex items-center"
        >
          <b-col
            md="4"
            class="p-0"
          >
            Nama Panggilan
          </b-col>
          <b-col
            md="7"
            class="border rounded-[8px] p-[7px]"
          >
            {{ dataDetail.nick_name.new || dataDetail.nick_name.old || '-' }}
          </b-col>
          <b-col
            v-if="isNewData(dataDetail.nick_name.new, dataDetail.nick_name.old)"
            cols="1"
            class="p-0 flex"
          >
            <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
              <span
                id="nick-name"
                class=" k-info-circle h-fit rounded-full text-white text-2xl"
              />
              <BPopover
                target="nick-name"
                triggers="hover"
                placement="top"
              ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.nick_name.old || 'Tidak ada data sebelumnya' }}</span></BPopover>
            </div>
          </b-col>
        </b-col>

        <b-col
          md="12"
          class="p-0 flex items-center"
        >
          <b-col
            md="4"
            class="p-0"
          >
            Tanggal Lahir
          </b-col>
          <b-col
            md="7"
            class="border rounded-[8px] p-[7px]"
          >
            {{ DAY_MONTH_YEAR(dataDetail.birth_date.new) || DAY_MONTH_YEAR(dataDetail.birth_date.old) || '-' }}
          </b-col>
          <b-col
            v-if="isNewData(dataDetail.birth_date.new, dataDetail.birth_date.old)"
            cols="1"
            class="p-0 flex"
          >
            <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
              <span
                id="birth-date"
                class=" k-info-circle h-fit rounded-full text-white text-2xl"
              />
              <BPopover
                target="birth-date"
                triggers="hover"
                placement="top"
              ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ DAY_MONTH_YEAR(dataDetail.birth_date.old) || 'Tidak ada data sebelumnya' }}</span></BPopover>
            </div>
          </b-col>
        </b-col>

        <b-col
          md="12"
          class="p-0 flex items-center"
        >
          <b-col
            md="4"
            class="p-0"
          >
            Lokasi
          </b-col>
          <b-col
            md="7"
            class="border rounded-[8px] p-[7px]"
          >
            {{ dataDetail.subdistrict_id.new ? dataDetail.subdistrict_id.new.name : dataDetail.subdistrict_id.old ? dataDetail.subdistrict_id.old.name : '-' }}
          </b-col>
          <b-col
            v-if="isNewData(JSON.stringify(dataDetail.subdistrict_id.new), JSON.stringify(dataDetail.subdistrict_id.old))"
            cols="1"
            class="p-0 flex"
          >
            <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
              <span
                id="location"
                class=" k-info-circle h-fit rounded-full text-white text-2xl"
              />
              <BPopover
                target="location"
                triggers="hover"
                placement="top"
              ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.subdistrict_id.old ? dataDetail.subdistrict_id.old.name : 'Tidak ada data sebelumnya' }}</span></BPopover>
            </div>
          </b-col>
        </b-col>

        <b-col
          md="12"
          class="p-0 flex items-center"
        >
          <b-col
            md="4"
            class="p-0"
          >
            Nomor HP
          </b-col>
          <b-col
            md="7"
            class="border rounded-[8px] p-[7px]"
          >
            {{ dataDetail.no_hp.new || dataDetail.no_hp.old || '-' }}
          </b-col>
          <b-col
            v-if="isNewData(dataDetail.no_hp.new, dataDetail.no_hp.old)"
            cols="1"
            class="p-0 flex"
          >
            <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
              <span
                id="phone"
                class=" k-info-circle h-fit rounded-full text-white text-2xl"
              />
              <BPopover
                target="phone"
                triggers="hover"
                placement="top"
              ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.no_hp.old || 'Tidak ada data sebelumnya' }}</span></BPopover>
            </div>
          </b-col>
        </b-col>

        <b-col
          md="12"
          class="p-0 flex items-center"
        >
          <b-col
            md="4"
            class="p-0"
          >
            Status Perkawinan
          </b-col>
          <b-col
            md="7"
            class="border rounded-[8px] p-[7px]"
          >
            {{ (dataDetail.martial_status.new !== null && ( dataDetail.martial_status.new !== dataDetail.martial_status.old )) ? (dataDetail.martial_status.new ? 'Sudah Menikah' : 'Belum Menikah') : (dataDetail.martial_status.old ? 'Sudah Menikah' : 'Belum Menikah') }}
          </b-col>
          <b-col
            v-if="dataDetail.martial_status.new !== dataDetail.martial_status.old"
            cols="1"
            class="p-0 flex"
          >
            <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
              <span
                id="marital"
                class=" k-info-circle h-fit rounded-full text-white text-2xl"
              />
              <BPopover
                target="marital"
                triggers="hover"
                placement="top"
              ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.martial_status.old ? 'Sudah Menikah' : 'Belum Menikah' || '-' }}</span></BPopover>
            </div>
          </b-col>
        </b-col>

        <b-col
          md="12"
          class="p-0 flex items-center"
        >
          <b-col
            md="4"
            class="p-0"
          >
            Alamat
          </b-col>
          <b-col
            md="7"
            class="border rounded-[8px] p-[7px]"
          >
            {{ dataDetail.address.new || dataDetail.address.old || '-' }}
          </b-col>
          <b-col
            v-if="isNewData(dataDetail.address.new, dataDetail.address.old)"
            cols="1"
            class="p-0 flex"
          >
            <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
              <span
                id="address"
                class=" k-info-circle h-fit rounded-full text-white text-2xl"
              />
              <BPopover
                target="address"
                triggers="hover"
                placement="top"
              ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.address.old || 'Tidak ada data sebelumnya' }}</span></BPopover>
            </div>
          </b-col>
        </b-col>
      </BRow>
    </BCol>
    <BCol
      md="3"
      class="d-flex justify-start gap-[5px] p-0 ml-auto"
    >
      <div class="profile-preview d-flex justify-center">
        <span
          v-if="!dataDetail.profile_image.new && !dataDetail.profile_image.old"
          class="k-user-square text-[180px] text-[#C2C2C2]"
        />
        <div
          v-else
          class="img-preview d-flex items-center justify-center relative"
        >
          <img
            v-if="dataDetail.profile_image.new || dataDetail.profile_image.old"
            :src="dataDetail.profile_image.new || dataDetail.profile_image.old"
            alt="preview"
            class="image-preview"
          >
          <img
            v-else
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/image.svg"
            alt="no-image"
          >
          <div
            class="absolute __overlay items-center justify-center"
          >
            <button
              class="d-flex justify-center items-center bg-gray-50 rounded px-[10px] py-[5px] gap-10"
              @click="$bvModal.show('modalMedia')"
            >
              <span class="k-eye text-2xl font-semibold" />
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="isNewData(dataDetail.profile_image.new, dataDetail.profile_image.old)"
        class="ml-[10px] rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]"
      >
        <span
          id="photo-url"
          class=" k-info-circle h-fit rounded-full text-white text-2xl"
        />
      </div>
    </BCol>
    <BModal
      id="modalMedia"
      ref="modalMedia"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
      hide-header
      size="lg"
      :centered="true"
    >
      <div class="rounded-[16px] flex flex-column w-full bg-white gap-[16px]">
        <div class="flex items-center">
          <span class="text-[#333333] text-[20px] font-semibold">Request New Edit Photo</span>
          <span
            class="ml-auto py-[8px] px-[16px] cursor-pointer bg-[#F95031] h-fit k-close rounded-xl text-3xl text-white"
            @click="$bvModal.hide('modalMedia')"
          />
        </div>
        <div class="flex items-center gap-[10px]">
          <div
            id="new-photo"
            class="p-[10px] w-full border rounded-xl h-full"
          >
            <img
              :src="dataDetail.profile_image.new"
              alt="new photo"
              class="w-100"
            >
          </div>
          <div
            id="old-photo"
            class="p-[10px] w-full border rounded-xl h-full"
          >
            <img
              :src="dataDetail.profile_image.old || 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/image.svg'"
              alt="new photo"
              class="w-100"
            >
          </div>
        </div>
        <div class="flex items-center gap-[10px]">
          <div
            class="p-[10px] w-full rounded-xl h-full justify-center flex"
          >
            <div class="flex jusify-center bg-[#FCEBBE] w-fit rounded p-[5px]">
              <span class="text-[#FBA63C]">New Request</span>
              <span class="ml-[10px] rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
                <span class=" k-info-circle h-fit rounded-full text-white text-2xl" />
              </span>
            </div>
          </div>
          <div
            class="p-[10px] w-full rounded-xl h-full justify-center flex"
          >
            Foto Lama
          </div>
        </div>
      </div>
    </BModal>
  </BRow>
</template>

<script>
import { DAY_MONTH_YEAR } from '@/libs/filterDate'

export default {
  props: {
    dataDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      DAY_MONTH_YEAR,
    }
  },
  methods: {
    isNewData(newData, oldData) {
      if (newData && oldData) {
        if (newData === oldData) {
          return false
        }
        return true
      }
      if (newData && oldData === null) {
        return true
      }

      return false
    },
  },
}
</script>

<style lang="scss" scoped>
  .profile-preview {
    max-width: 200px;
    height: 200px;
    border-radius: 8px;
    background: #F8F8F8;
    box-shadow: 0px 0px 16.5px 0px rgba(0, 0, 0, 0.15);
  }
  .img-preview {
    height: 100%;
    width: 100%;
    background-color: #f4f4f4;
    border: 1px solid #828282;
    border-radius: 16px;
    overflow: hidden;
  }
  .image-preview {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .__overlay {
    display: none;
    background: #33333340;
    border-radius: 16px;
    border: 1px solid #828282;
    transition: ease-in-out .5;
  }
  .img-preview:hover > .__overlay {
    display: flex;
    height: 100%;
    width: 100%;
  }
</style>
