<template>
  <BModal
    id="modalApproval"
    ref="modalApproval"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    :centered="true"
    style="width: 600px;"
  >
    <div class="text-center my-1">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        class="m-auto"
      >
      <div class="font-semibold text-2xl text-black my-[20px]">
        Setujui perubahan ini
      </div>
      <p class="text-black my-[20px]">
        Perubahan data ini akan secara otomatis diupdate dengan Data Talent
      </p>
      <div class="flex justify-center">
        <BButton
          size="md"
          variant="outline-primary"
          class="mr-1 w-full"
          @click="$bvModal.hide('modalApproval')"
        >
          Tutup
        </BButton>
        <BButton
          :disabled="loading"
          size="md"
          class="w-full"
          variant="primary"
          block
          @click="$emit('approve')"
        >
          <BSpinner
            v-if="loading"
            small
          />
          Kirim

        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
