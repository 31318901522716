<template>
  <div class="flex flex-column gap-[16px] px-[24px] mt-[16px]">
    <b-col
      md="8"
      class="p-0 flex items-center"
    >
      <b-col
        md="4"
        class="p-0"
      >
        Pendidikan Terakhir
      </b-col>
      <b-col
        md="7"
        class="border rounded-[8px] p-[7px]"
      >
        {{ dataDetail.education.new }}
      </b-col>
      <b-col
        v-if="isNewData(dataDetail.education.new, dataDetail.education.old)"
        cols="1"
        class="p-0 flex"
      >
        <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
          <span
            id="bank-name-old"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="bank-name-old"
            triggers="hover"
            placement="top"
          ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.education.old }}</span></BPopover>
        </div>
      </b-col>
    </b-col>

    <b-col
      md="8"
      class="p-0 flex items-center"
    >
      <b-col
        md="4"
        class="p-0"
      >
        Pengalaman Kerja
      </b-col>
      <b-col
        md="7"
        class="border rounded-[8px] p-[7px]"
      >
        {{ dataDetail.work_experience.new ? 'Ada' : 'Tidak Ada' }}
      </b-col>
      <b-col
        v-if="dataDetail.work_experience.new !== dataDetail.work_experience.old"
        cols="1"
        class="p-0 flex"
      >
        <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
          <span
            id="rek-num-old"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="rek-num-old"
            triggers="hover"
            placement="top"
          ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.work_experience.old ? 'Ada' : 'Tidak Ada' }}</span></BPopover>
        </div>
      </b-col>
    </b-col>

    <b-col
      md="8"
      class="p-0 flex items-center"
    >
      <b-col
        md="4"
        class="p-0"
      >
        Deskripsi Pengalaman
      </b-col>
      <b-col
        md="7"
        class="border rounded-[8px] p-[7px]"
      >
        {{ dataDetail.experience_description.new || '-' }}
      </b-col>
      <b-col
        v-if="isNewData(dataDetail.experience_description.new, dataDetail.experience_description.old)"
        cols="1"
        class="p-0 flex"
      >
        <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
          <span
            id="holder-name-old"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="holder-name-old"
            triggers="hover"
            placement="top"
          ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.experience_description.old || '-' }}</span></BPopover>
        </div>
      </b-col>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    dataDetail: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    isNewData(newData, oldData) {
      if (newData && oldData) {
        if (newData === oldData) {
          return false
        }
        return true
      }
      if (newData && oldData === '') {
        return true
      }

      return false
    },
  },
}
</script>
