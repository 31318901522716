<template>
  <div class="flex flex-column gap-[16px] px-[24px] mt-[16px]">
    <b-col
      md="8"
      class="p-0 flex items-center"
    >
      <b-col
        md="4"
        class="p-0"
      >
        Partner
      </b-col>
      <b-col
        md="7"
        class="border rounded-[8px] p-[7px]"
      >
        {{ dataDetail.partner_id.new ? (dataDetail.partner_id.new.name || '-') : dataDetail.partner_id.old ? (dataDetail.partner_id.old.name || '-') : '-' }}
      </b-col>
      <b-col
        v-if="isNewData(JSON.stringify(dataDetail.partner_id.new), JSON.stringify(dataDetail.partner_id.old))"
        cols="1"
        class="p-0 flex"
      >
        <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
          <span
            id="partner"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="partner"
            triggers="hover"
            placement="top"
          ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.partner_id.old ? dataDetail.partner_id.old.name : 'Tidak ada data sebelumnya' }}</span></BPopover>
        </div>
      </b-col>
    </b-col>

    <b-col
      md="8"
      class="p-0 flex items-center"
    >
      <b-col
        md="4"
        class="p-0"
      >
        Skill Role
      </b-col>
      <b-col
        md="7"
        class="border rounded-[8px] p-[7px]"
      >
        {{ dataDetail.skill_role_id.new ? dataDetail.skill_role_id.new.name : dataDetail.skill_role_id.old ? dataDetail.skill_role_id.old.name : '-' }}
      </b-col>
      <b-col
        v-if="isNewData(JSON.stringify(dataDetail.skill_role_id.new), JSON.stringify(dataDetail.skill_role_id.old))"
        cols="1"
        class="p-0 flex"
      >
        <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
          <span
            id="skill-role"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="skill-role"
            triggers="hover"
            placement="top"
          ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.skill_role_id.old ? dataDetail.skill_role_id.old.name : 'Tidak ada data sebelumnya' }}</span></BPopover>
        </div>
      </b-col>
    </b-col>

    <b-col
      md="8"
      class="p-0 flex items-center"
    >
      <b-col
        md="4"
        class="p-0"
      >
        Keahlian
      </b-col>
      <b-col
        md="7"
        class="p-0 flex items-center gap-[12px]"
      >
        <div
          v-for="(e, i) in dataDetail.expert_id.new"
          :key="i"
          class="flex items-center px-1 py-[5px] gap-1 rounded-full bg-[#FCD4BE] text-black"
        >
          <span>{{ e.name }}</span>
        </div>
      </b-col>
      <b-col
        v-if="isNewData(JSON.stringify(dataDetail.expert_id.new), JSON.stringify(dataDetail.expert_id.old))"
        cols="1"
        class="p-0 flex"
      >
        <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
          <span
            id="skill"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="skill"
            triggers="hover"
            placement="top"
          ><span class="text-muted">Detail Perubahan:</span> <br> <span class="font-semibold">{{ dataChanges }}</span></BPopover>
        </div>
      </b-col>
    </b-col>

    <b-col
      v-if="dataDetail.skill_role_id.new.name === 'Admin Marketplace'"
      md="8"
      class="items-center border-2 py-2 border-dashed rounded-lg px-2 pl-0"
    >
      <b-col
        md="12"
        class="p-0 flex"
      >
        <h4 class="font-semibold mb-[12px] text-[14px]">
          Performa Chat
        </h4>
        <div
          v-if="isNewData(JSON.stringify(dataDetail.performance.new), JSON.stringify(dataDetail.performance.old))"
          class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]"
        >
          <span
            id="performance"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="performance"
            triggers="hover"
          ><span class="text-muted">Detail Perubahan:</span>
            <template v-if="!dataDetail.performance.old">
              <br><span class="font-semibold">Tidak ada data sebelumnya</span>
            </template>
            <template
              v-for="(value, name, index) in dataDetail.performance.old"
            >
              <br :key="index + 'a'"> {{ name }}: <span
                :key="index + 'b'"
                class="font-semibold"
              >{{ value }}</span>
            </template>
          </BPopover>
        </div>
      </b-col>
      <b-col
        v-for="(value, name, index) in dataDetail.performance.new"
        :key="index"
        md="12"
        class="p-0 flex items-center mb-[12px] last:mb-0"
      >
        <b-col
          md="4"
          class="p-0"
        >
          {{ name }}
        </b-col>
        <b-col
          md="8"
          class="border rounded-[8px] flex py-0 pr-0 items-center"
        >
          {{ value.split(/[ /]+/)[0] }}
          <span class="border-l px-1 align-middle leading-loose ml-auto">{{ value.split(/[ /]+/)[1] === '15' ? 'rate' : value.split(/[ /]+/)[1] }}</span>
        </b-col>
      </b-col>
    </b-col>

    <b-col
      v-if="dataDetail.skill_role_id.new.name === 'Customer Service'"
      md="8"
      class="p-0 flex items-center"
    >
      <b-col
        md="4"
        class="p-0"
      >
        Closing rate
      </b-col>
      <b-col
        md="7"
        class="border rounded-[8px] p-[7px] flex py-0 pr-0 items-center"
      >
        {{ dataDetail.closing_rate.new ? dataDetail.closing_rate.new : dataDetail.closing_rate.old ? dataDetail.closing_rate.old : '-' }}
        <span class="border-l px-1 text-xl align-middle leading-loose ml-auto">%</span>
      </b-col>
      <b-col
        v-if="isNewData(dataDetail.closing_rate.new, dataDetail.closing_rate.old)"
        cols="1"
        class="p-0 flex"
      >
        <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
          <span
            id="closing-rate"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="closing-rate"
            triggers="hover"
            placement="top"
          ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.closing_rate.old ? dataDetail.closing_rate.old : 'Tidak ada data sebelumnya' }}</span></BPopover>
        </div>
      </b-col>
    </b-col>

    <b-col
      md="8"
      class="p-0 flex items-center"
    >
      <b-col
        md="4"
        class="p-0"
      >
        Sektor Bisnis
      </b-col>
      <b-col
        md="7"
        class="border rounded-[8px] p-[7px]"
      >
        {{ dataDetail.business_sector_id.new ? dataDetail.business_sector_id.new.name : dataDetail.business_sector_id.old ? dataDetail.business_sector_id.old.name : '-' }}
      </b-col>
      <b-col
        v-if="isNewData(JSON.stringify(dataDetail.business_sector_id.new), JSON.stringify(dataDetail.business_sector_id.old))"
        cols="1"
        class="p-0 flex"
      >
        <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
          <span
            id="sector-bussiness"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="sector-bussiness"
            triggers="hover"
            placement="top"
          ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.business_sector_id.old ? dataDetail.business_sector_id.old.name : 'Tidak ada data sebelumnya' }}</span></BPopover>
        </div>
      </b-col>
    </b-col>

    <b-col
      md="8"
      class="p-0 flex items-center"
    >
      <b-col
        md="4"
        class="p-0"
      >
        Tanggal Mulai
      </b-col>
      <b-col
        md="7"
        class="border rounded-[8px] p-[7px]"
      >
        {{ dataDetail.start_date.new ? DAY_MONTH_YEAR(dataDetail.start_date.new) : '-' }}
      </b-col>
      <b-col
        v-if="isNewData(dataDetail.start_date.new, dataDetail.start_date.old)"
        cols="1"
        class="p-0 flex"
      >
        <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
          <span
            id="rek-num-old"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="rek-num-old"
            triggers="hover"
            placement="top"
          ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.start_date.old ? DAY_MONTH_YEAR(dataDetail.start_date.old) : 'Tidak ada data sebelumnya' }}</span></BPopover>
        </div>
      </b-col>
    </b-col>

    <b-col
      md="8"
      class="p-0 flex items-center"
    >
      <b-col
        md="4"
        class="p-0"
      >
        Tanggal Selesai
      </b-col>
      <b-col
        md="7"
        class="border rounded-[8px] p-[7px]"
      >
        {{ dataDetail.end_date.new ? DAY_MONTH_YEAR(dataDetail.end_date.new) : '-' }}
      </b-col>
      <b-col
        v-if="isNewData(dataDetail.end_date.new, dataDetail.end_date.old)"
        cols="1"
        class="p-0 flex"
      >
        <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
          <span
            id="end-date"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="end-date"
            triggers="hover"
            placement="top"
          ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.end_date.old ? DAY_MONTH_YEAR(dataDetail.end_date.old) : 'Tidak ada data sebelumnya' }}</span></BPopover>
        </div>
      </b-col>
    </b-col>

    <b-col
      md="8"
      class="p-0 flex items-center"
    >
      <b-col
        md="4"
        class="p-0"
      >
        Deskripsi
      </b-col>
      <b-col
        md="7"
        class="border rounded-[8px] p-[7px]"
      >
        {{ dataDetail.description.new || dataDetail.description.old || '-' }}
      </b-col>
      <b-col
        v-if="isNewData(dataDetail.description.new, dataDetail.description.old)"
        cols="1"
        class="p-0 flex"
      >
        <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
          <span
            id="description"
            class=" k-info-circle h-fit rounded-full text-white text-2xl"
          />
          <BPopover
            target="description"
            triggers="hover"
            placement="top"
          ><span class="text-muted">Detail Existing:</span> <br> <span class="font-semibold">{{ dataDetail.description.old || 'Tidak ada data sebelumnya' }}</span></BPopover>
        </div>
      </b-col>
    </b-col>
  </div>
</template>

<script>
import { DAY_MONTH_YEAR } from '@/libs/filterDate'

export default {
  props: {
    dataDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      dataChanges: null,
    }
  },
  mounted() {
    const newData = this.dataDetail.expert_id.new?.filter(e => !this.dataDetail.expert_id.old?.some(oldObj => oldObj.id === e.id))
    const deletedData = this.dataDetail.expert_id.old?.filter(e => !this.dataDetail.expert_id.new?.some(newObj => newObj.id === e.id))
    const newDataMap = newData ? newData.map(e => `${e.name} (Baru)`) : []
    const deletedDataMap = deletedData ? deletedData.map(e => `${e.name}`) : []
    this.dataChanges = [...newDataMap, ...deletedDataMap].join(', ')
  },
  methods: {
    isNewData(newData, oldData) {
      if (newData && oldData) {
        if (newData === oldData) {
          return false
        }
        return true
      }

      if (newData && (oldData === null || oldData === '')) {
        return true
      }

      return false
    },
  },
}
</script>
