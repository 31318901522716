<template>
  <BModal
    id="modalRejection"
    ref="modalRejection"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    :centered="true"
    style="width: 600px;"
  >
    <div class="text-center my-1">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        class="m-auto"
      >
      <div class="font-semibold text-2xl text-black my-[20px]">
        Berikan alasan penolakan
      </div>
      <p class="text-black my-[20px]">
        Berikan catatan terkait penolakan perubahan data ini
      </p>
      <b-form-textarea
        v-model="reason"
        placeholder="alasan penolakan"
        class="mb-[20px]"
      />
      <div class="flex justify-center">
        <BButton
          size="md"
          variant="outline-primary"
          class="mr-1 w-full"
          @click="$bvModal.hide('modalRejection')"
        >
          Tutup
        </BButton>
        <BButton
          :disabled="loading || reason.length === 0"
          size="md"
          variant="primary"
          class="w-full"
          block
          @click="$emit('reject', reason)"
        >
          <BSpinner
            v-if="loading"
            small
          />
          Kirim

        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reason: '',
    }
  },
}
</script>
