<template>
  <div class="flex gap-[16px] mt-[16px]">
    <b-col
      md="8"
      class="p-0 flex items-center gap-[12px]"
    >
      <div
        v-for="(e, i) in dataDetail.expert_id.new"
        :key="i"
        class="flex items-center px-1 py-[5px] gap-1 rounded-full bg-[#FCD4BE] text-black"
      >
        <span>{{ e.name }}</span>
      </div>
    </b-col>
    <b-col
      v-if="isNewData(JSON.stringify(dataDetail.expert_id.new), JSON.stringify(dataDetail.expert_id.old))"
      cols="1"
      class="p-0 ml-auto flex"
    >
      <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
        <span
          id="skills"
          class=" k-info-circle h-fit rounded-full text-white text-2xl"
        />
        <BPopover
          target="skills"
          triggers="hover"
          placement="top"
        ><span class="text-muted">Detail Perubahan:</span> <br> <span class="font-semibold">{{ dataChanges }}</span></BPopover>
      </div>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    dataDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataChanges: null,
    }
  },
  mounted() {
    const newData = this.dataDetail.expert_id.new.filter(e => !this.dataDetail.expert_id.old.some(oldObj => oldObj.id === e.id))
    const deletedData = this.dataDetail.expert_id.old.filter(e => !this.dataDetail.expert_id.new.includes(e))

    this.dataChanges = [...newData.map(e => `${e.name} (Baru)`), ...deletedData.map(e => `${e.name}`)].join(', ')
  },
  methods: {
    isNewData(newData, oldData) {
      if (newData && oldData) {
        if (newData === oldData) {
          return false
        }
        return true
      }
      if (newData && oldData === '') {
        return true
      }

      return false
    },
  },
}
</script>
