<template>
  <div class="flex gap-[16px] px-[24px] mt-[16px]">
    <b-col
      md="8"
      class="p-0 flex items-center"
    >
      <div
        class="flex gap-[32px] border-b last:border-b-0 py-[20px]"
      >
        <div
          class="min-w-[175px] h-[110px] bg-[#f8f8f8] cursor-pointer relative border rounded-xl flex justify-center items-center overflow-hidden"
          @click="toLink(dataDetail.file_url.new)"
        >
          <span
            class="k-link-21 text-5xl font-bold text-black -rotate-[15deg]"
          />
        </div>
        <div class="flex justify-between w-full">
          <div class="title flex-col">
            <p class="text-8 font-semibold text-[#333333]">
              {{ dataDetail.title.new }}
            </p>
            <p class="text-7 text-muted">
              {{ dataDetail.description.new || '-' }}
            </p>
          </div>
        </div>
      </div>
    </b-col>
    <b-col
      v-if="isNewData(dataDetail)"
      cols="1"
      class="p-0 ml-auto flex"
    >
      <div class="ml-auto rounded-full h-[22px] w-[22px] flex items-center justify-center bg-[#FBA63C]">
        <span
          id="portfolio"
          class=" k-info-circle h-fit rounded-full text-white text-2xl"
        />
        <BPopover
          target="portfolio"
          triggers="hover"
          placement="top"
        ><span class="text-muted">Detail Perubahan:</span>
          <template v-if="!dataDetail.title.old && !dataDetail.description.old && !dataDetail.file_url.old">
            <br><span class="font-semibold">Tidak ada data sebelumnya</span>
          </template>
          <template v-else>
            <br> Judul: <span class="font-semibold">{{ dataDetail.title.old }}</span>
            <br> Deskripsi: <span class="font-semibold">{{ dataDetail.description.old }}</span>
            <br> File: <a
              :href="dataDetail.file_url.old"
              class="font-semibold"
            >{{ dataDetail.file_url.old }}</a>
          </template>
        </BPopover>
      </div>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    dataDetail: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    toLink(link) {
      window.open(link, '_blank')
    },
    isNewData(data) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in data) {
        if (data[key].old !== data[key].new) {
          return true
        }
      }
      return false
    },
  },
}
</script>
