<template>
  <div class="bg-white rounded-[8px] py-[32px] px-[24px] text-[#333333]">
    <div class="flex gap-8 items-center mb-[32px]">
      <button
        class="bg-[#F95031] flex items-center text-xl rounded-md text-white px-[10px] py-[5px]"
        @click="$router.back()"
      >
        <span class="k-arrow-left-2" />
      </button>
      <span class="font-semibold text-[24px] text-[#333333]">Approvement</span>
      <span
        v-if="dataDetail.status !== 'request'"
        class="p-[5px] rounded ml-auto text-[#828282]"
      >{{ getDayName(dataDetail.date) }} {{ DAY_MONTH_YEAR(dataDetail.date) }}</span>
      <span
        v-if="dataDetail.status !== 'request'"
        class="p-[5px] rounded ml-[10px]"
        :class="dataDetail.status === 'cancel' ? 'bg-[#FFEDED] text-[#79170A]' : 'bg-[#DCF3EB] text-[#10673E]'"
      >{{ dataDetail.status === 'cancel' ? 'Ditolak' : 'Disetujui' }}</span>
    </div>
    <div
      v-if="dataDetail.status === 'cancel'"
      class="flex flex-column bg-[#FFEDED] rounded mb-[16px] gap-[10px] px-[12px] py-[8px]"
      style="border: 1px solid #E31A1A;"
    >
      <span class="text-[16px] font-semibold">Catatan:</span>
      <span>{{ dataDetail.note || '-' }}</span>
    </div>
    <div>
      <div class="border-b pb-[16px] border-dashed head-section font-semibold text-lg">
        {{ dataDetail ? typeRequest(dataDetail.type_request) : '-' }}
      </div>
    </div>
    <div class="h-[calc(100vh-310px)] overflow-y-auto">
      <component
        :is="isComponent(dataDetail.type_request)"
        v-if="dataDetail.detail"
        :data-detail="dataDetail.detail"
      />
    </div>

    <div
      v-if="dataDetail.status === 'request'"
      class="d-flex justify-content-end bg-white rounded"
    >
      <button
        type="kembali"
        class="mr-50 button-secondary"
        @click.prevent="$bvModal.show('modalRejection')"
      >
        Tolak Perubahan
      </button>
      <button
        class="button-primary w-[200px]"
        @click.prevent="$bvModal.show('modalApproval')"
      >
        Setujui
      </button>
    </div>
    <ModalApproval
      :loading="loadingSubmit"
      @approve="sendApprovement(true, null)"
    />
    <ModalRejection
      :loading="loadingSubmit"
      @reject="rejection"
    />
  </div>
</template>

<script>
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import Bank from './request-type/Bank.vue'
import BasicInformation from './request-type/BasicInformation.vue'
import Skills from './request-type/Skills.vue'
import EducationWorkExperience from './request-type/EducationWorkExperience.vue'
import Portfolio from './request-type/Portfolio.vue'
import WorkHistory from './request-type/WorkExperience.vue'
import ModalApproval from './ModalApproval.vue'
import ModalRejection from './ModalRejection.vue'

export default {
  components: {
    Bank,
    BasicInformation,
    Skills,
    EducationWorkExperience,
    Portfolio,
    WorkHistory,
    ModalApproval,
    ModalRejection,
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      alertError,
      alertSuccess,
      loading: false,
      loadingSubmit: false,
      dataDetail: {},
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      this.loading = true
      this.offset = 0
      const url = `v1/talent_update/${this.$route.params.uuid}/approvement`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.dataDetail = data
        })
        .catch(err => {
          this.loading = false
          if (err.response.status === 500) this.$toast_error({ message: 'Internal server error' })
          else this.alertError(err)
        })
    },
    async sendApprovement(value, note) {
      this.loadingSubmit = true
      this.offset = 0
      const payload = {
        status: value,
        note,
      }
      const url = `v1/talent_update/${this.$route.params.uuid}/update`
      await komtimAxiosIns
        .post(url, payload)
        .then(res => {
          this.loading = false
          this.alertSuccess('Data berhasil di update')
          this.$bvModal.hide('modalRejection')
          this.$bvModal.hide('modalApproval')
          this.getDetail()
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    typeRequest(type) {
      switch (type) {
        case 'basic_information': return 'Informasi Dasar'
        case 'work_history': return 'Pengalaman Kerja'
        case 'bank_account': return 'Akun Bank'
        case 'skill': return 'Keahlian'
        case 'portfolio': return 'Portofolio'
        case 'education_work_experience': return 'Pendidikan & Pengalaman Kerja'
        default: return '-'
      }
    },
    isComponent(type) {
      switch (type) {
        case 'basic_information': return 'basic-information'
        case 'work_history': return 'work-history'
        case 'bank_account': return 'bank'
        case 'skill': return 'skills'
        case 'portfolio': return 'portfolio'
        case 'education_work_experience': return 'education-work-experience'
        default: return 'basic-information'
      }
    },
    classRequest(status) {
      switch (status) {
        case 'cancel': return 'status-reject'
        case 'accept': return 'status-approve'
        default: return 'status-request'
      }
    },
    statusRequest(status) {
      switch (status) {
        case 'cancel': return 'Ditolak'
        case 'accept': return 'Disetujui'
        default: return 'Request'
      }
    },
    rejection(value) {
      this.sendApprovement(false, value)
    },
    getDayName(dateString) {
      const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
      const date = new Date(dateString)
      const dayIndex = date.getDay()
      return days[dayIndex]
    },
  },
}
</script>

<style scoped>
.status-request {
  background: #FCEBBE;
  color: #AF6A13;
}
.status-reject {
  background: #FFEDED;
  color: #79170A;
}
.status-approve {
  background: #DCF3EB;
  color: #10673E;
}
</style>
